<template>
  <el-form v-loading.fullscreen.lock="loading" @submit.prevent :model="ruleForm" :rules="rules" :label-position="labelPosition" ref="ruleForm">
    <div class="reset_zone login_zone">
      <BackButton class="my-4" text="返回" url="/login/forgetPassword"></BackButton>
      <div class="login_account">
        <el-form-item prop="account" label="帳號">
          <el-input ref="autoFocus" v-model="ruleForm.account" placeholder="請輸入會員Email" />
        </el-form-item>
      </div>
      <div class="temp_password">
        <el-form-item prop="tempPassword" label="臨時密碼">
          <el-input v-model="ruleForm.tempPassword" type="password" autocomplete="new-password" show-password placeholder="請輸入臨時密碼" />
        </el-form-item>
      </div>
      <div class="register_password">
        <el-form-item prop="newPassword" label="設定密碼 (含英數共8-20碼)">
          <el-input v-model="ruleForm.newPassword" autocomplete="off" show-password placeholder="請設定密碼" />
        </el-form-item>
      </div>
      <div class="register_passwordConfirm">
        <el-form-item prop="passwordConfirm" label="確認密碼">
          <el-input v-model="ruleForm.passwordConfirm" @keydown.enter.prevent="resetPassword" show-password autocomplete="off" placeholder="請再一次輸入密碼" />
        </el-form-item>
      </div>
      <ConfirmButton class="register_btn" @click="resetPassword" text="更改密碼"></ConfirmButton>
    </div>
  </el-form>
</template>

<script>
import ConfirmButton from "../../Common/CommonElement/ConfirmButton.vue";
import BackButton from "../../Common/CommonElement/BackButton.vue";

export default {
  name: "RegisterComponent",
  components: {
    ConfirmButton,
    BackButton,
  },
  created() {
    this.ruleForm.account = this.$store.state.resetPassword.account;
  },
  mounted() {
    this.$nextTick(() => this.$refs.autoFocus.focus());
  },
  data() {
    const validateAccount = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("此為必填資訊"));
      } else if (value.match(/^09[0-9]{8}$/) || value.match(/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/)) {
        callback();
      } else {
        callback(new Error("請填入正確格式"));
      }
    };
    const validateTempPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("此為必填資訊"));
      } else if (value.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{10,10}$/)) {
        callback();
      } else {
        callback(new Error("請填入正確格式"));
      }
    };
    const validateNewPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("密碼不可為空"));
      } else {
        if (this.ruleForm.passwordConfirm !== "") {
          this.$refs.ruleForm.validateField("passwordConfirm");
        }
        callback();
      }
    };
    const validatePasswordConfirm = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("請再次輸入密碼"));
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error("密碼輸入錯誤"));
      } else {
        callback();
      }
    };
    return {
      labelPosition: "top",
      loading: false,
      ruleForm: {
        account: "",
        tempPassword: "",
        newPassword: "",
        passwordConfirm: "",
      },
      rules: {
        account: [{ validator: validateAccount, trigger: "change" }],
        tempPassword: [{ validator: validateTempPassword, trigger: "change" }],
        newPassword: [
          { validator: validateNewPassword, trigger: "change" },
          {
            pattern: /^(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%]).{8,}$/,
            message: "密碼條件：英文大寫、英文小寫、數字、符號 ! @ # $ %",
            trigger: "change",
          },
        ],
        passwordConfirm: [{ validator: validatePasswordConfirm, trigger: "change" }],
      },
    };
  },
  methods: {
    async resetPassword() {
      const vm = this;
      vm.$refs["ruleForm"].validate(valid => {
        if (valid) {
          vm.loading = true;
          const user = {
            account: vm.ruleForm.account,
            tempPassword: vm.ruleForm.tempPassword,
            newPassword: vm.ruleForm.newPassword,
          };
          vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/account/password/temp`, user)
            .then(() => {
              vm.notify("success", "更改密碼成功！");
              vm.$router.push("/login");
            })
            .catch(err => {
              console.log(err.response.data);
              vm.notify("error", `更改密碼失敗，${err.response.data.Msg}!`);
            })
            .finally(() => (vm.loading = false));
        }
        else {
          vm.notify("warning", `請確認輸入資料是否正確!`);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reset_zone {
  height: 100%;
  text-align: start;
  .register_btn {
    color: white;
    width: 100%;
  }
  .el-form-item {
    margin-bottom: 1rem;
  }
}
:deep .el-form-item {
  label {
    font-weight: $main_fontWeight;
    color: $text_color;
    font-size: 1rem;
  }
}
</style>
