<template>
  <router-link :to="url">
    <i class="fa-solid fa-arrow-left-long"></i> <span>{{ text }} </span></router-link
  >
</template>

<script>
export default {
  name: "BackButton",
  props: ["text", "url"],
};
</script>

<style lang="scss" scoped>
a {
  display: flex;
  align-items: center;
  text-align: start;
  width: 5rem;
  font-weight: 700;
  color: $text_color;
  transition: $main_transition;
  text-decoration: none;
  &:hover {
    color: #000;
  }
  span {
    margin: 0 0.5rem;
  }
}
</style>
